.header {
  position: fixed;
  z-index: 0;
  height:396px;
  width: 100vw;
  background: transparent;
  transition: padding .2s, background .2s;
  background: url("../../../assets/images/bibliotheek.jpg") center center no-repeat;
  background-size: cover;
}
.header-gradient {
  width:100vw; height:396px;
  background: linear-gradient(139.17deg, rgba(0, 0, 0, 0.5616) -17%, rgba(0, 0, 0, 0.3588) 59.35%, rgba(0, 0, 0, 0.2964) 126.77%);
  background-size:cover;
  mix-blend-mode: normal;
}
.header__logo {
  margin-right: 48px;
}
.container-fluid {
  z-index:999;
  padding-top: 5vh;
  padding-left: 2vw;
}
.header-logo__image {
  width: 84px;
  height: 84px;
  object-fit: cover;
  transition: .2s;
}
.header__spacer {
  height: 396px;
  transition: height .2s;
}
.header__spacer a {
  margin-left: 12px;
}
.header__spacer a:first-child {
  margin-left: 0;
}

.header--sticky {
  background: #fff;
  padding: 5px 10px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, .1);
  .header-logo__image {
    width: 64px;
    height: 64px;
    object-fit: cover;
  }
  .header__spacer {
    height: 64px;
  }
}
