.social {
  text-decoration: none;
  display: inline-block;
}
.social-twitter {
  width: 28px;
  height: 28px;
  background: url("../../../assets/images/post-twitter.png") center no-repeat;
}

.social-facebook {
  width: 28px;
  height: 28px;
  background: url("../../../assets/images/post-facebook.png") center no-repeat;
}

.social-gplus {
  width: 28px;
  height: 28px;
  background: url("../../../assets/images/post-gplus.png") center no-repeat;
}

.social-linkedin {
  width: 28px;
  height: 28px;
  background: url("../../../assets/images/post-linkedin.png") center no-repeat;
}

.social-email {
  width: 30px;
  height: 28px;
  background: url("../../../assets/images/post-email.png") center no-repeat;
}

.whatsapp {
  width: 20px;
  height: 20px;
  background: url("../../../assets/images/whatsapp.png") center no-repeat;
  margin-right: 8px;
}
