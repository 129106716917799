.btn .material-icons { padding-right:10px; }

.btn-daan-ghost {
  border: 1px solid #fff;
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  height: 34px;
  border-radius: 34px;
  display: inline-flex;
  align-items: center;
  background: transparent;
  &:hover {
    color: #FF9000;
    background: #fff;
  }
}

.btn-daan-ghost-orange {
  border: 1px solid #FF9000;
  font-size: 13px;
  font-weight: 500;
  color: #FF9000;
  height: 34px;
  border-radius: 34px;
  display: inline-flex;
  align-items: center;
  background: transparent;
  &:hover {
    color: #fff;
    background: #FF9000;
  }
}
.btn-daan-ghost .material-icons {
  font-size: 16px;
  margin-right: 10px;
}

.btn-daan-white {
  border: 1px solid #fff;
  font-size: 14px;
  font-weight: 700;
  color: #FF9000;
  background: #fff;
  height: 34px;
  border-radius: 34px;
  display: inline-flex;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  &:hover {
    color: #fff;
    background: #FF9000;
    border: 1px solid #FF9000;
  }
}

.btn-daan-red {
  border: 1px solid #FF0000;
  font-size: 14px;
  font-weight: 700;
  color: #fff !important;
  background: #FF0000;
  height: 34px;
  border-radius: 34px;
  display: inline-flex;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  &:hover {
    color: #FF9000;
    background: #fff;
    border: 1px solid #FF0000;
  }
}

.btn-daan-orange {
  border: 1px solid #FF9000;
  font-size: 14px;
  font-weight: 700;
  color: #fff !important;
  background: #FF9000;
  height: 34px;
  border-radius: 34px;
  display: inline-flex;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  &:hover {
    color: #FF9000 !important;
    background: #fff;
    border: 1px solid #FF9000;
  }
}
.btn-daan-gray {
  border: 1px solid #E8E8E8;
  font-size: 14px;
  font-weight: 700;
  color: #B5B5B5;
  background: #E8E8E8;
  height: 34px;
  border-radius: 34px;
  display: inline-flex;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  &:hover {
    color: #818181;
    background: #B5B5B5;
    border: 1px solid #B5B5B5;
  }
}

.btn-daan-ghost-gray {
  border: 1px solid #B5B5B5;
  font-size: 14px;
  font-weight: 700;
  color: #B5B5B5;
  background: #fff;
  height: 34px;
  border-radius: 34px;
  display: inline-flex;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  &:hover {
    color: #FF9000;
    background: #fff;
    border: 1px solid #FF9000;
  }
}

.btn-daan-large {
  font-weight: 700;
  padding-left: 80px;
  padding-right: 80px;
  height: 48px;
  border-radius: 48px;
  font-size: 14px;
}

.btn-daan-block {
  padding-left: 24px;
  padding-right: 24px;
  display: block;
  line-height: 34px;
}

.btn-with-icon {
  .material-icons {
    margin-left: 8px;
    margin-right: 0;
    font-size: 11px;
  }
}

.btn-with-icon-left {
  .material-icons {
    margin-right: 8px;
    margin-left: 0;
    font-size: 14px;
    margin-top: 5px;
  }
}

.header--sticky {
  .btn-daan-white {
    background: #eee;
    color: #8F8F8F;
  }
  .btn-daan-ghost {
    color: #8F8F8F;
    border-color: #eee;
  }
}

.btn-small {
  height: 22px !important;
}