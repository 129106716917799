.header__navigation {
  font-size: 14px;
  

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
  }
  li {
    padding: 0;
    margin: 0 24px 0 0;
    &:last-child {
      margin-right: 0;
    }
  }
  a {
    display: block;
    color:#fff;
    font-weight:600;

    text-decoration: none;
    transition: .2s linear;
    &:hover {
      color: rgba(255, 255, 255, .8);
    }
  }
  .is-active {
    color: rgba(255, 255, 255, 1);
  }
}

.footer-last__navigation {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  li {
    margin-right: 36px;
    &:last-child {
      margin-right: 0;
    }
  }
  li a {
    color: rgba(0, 0, 0, .3);
  }
}

/** STICKY **/
.header--sticky {
  .header__navigation {
    a {
      color: rgba(0, 0, 0, .4);
      &:hover {
        color: rgba(0, 0, 0, .8);
      }
    }
    .is-active {
      color: rgba(0, 0, 0, 1);
    }
  }
}