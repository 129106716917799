.text-field {
  background: #fff;
  border: 1px solid #e3e3e3;
  height: 46px;
  line-height: 46px;
  color: #CDCDCD;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 4px;
  margin-bottom: 12px;
}
.select-field {
  background: #fff;
  border: 1px solid #e3e3e3;
  height: 46px;
  line-height: 46px;
  color: #CDCDCD;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 4px;
  margin-bottom: 12px;
  position: relative;
  &:after {
    content: 'keyboard_arrow_down';
    font-family: "Material Icons";
    color: rgba(0, 0, 0, 0.22);
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 12px;
    position: absolute;
    height: 46px;
    top: 0;

  }
}