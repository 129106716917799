.footer { position: absolute; width:100%; }
.footer__content {
  background: #F5F5F5;
}
.footer-content__title {
  font-weight: 700;
  font-size: 22px;
  color: #424242;
  margin: 38px 0 12px;
}
.footer-content__text {
  font-size: 16px;
  line-height: 22px;
  font-weight: 300;
  color: #454545;
}
.footer-content__available {
  margin: 12px 0 38px;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  display: inline-flex;
  align-items: center;
  .material-icons {
    margin-right: 6px;
  }
}
.footer-content__available-items {
  margin: 10px 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  display: flex;
  align-items: center;
  .material-icons {
    margin-right: 6px;
  }
}

.footer__last {
  display:none;
  height: 50px;
  background: #fff;
  font-size: 12px;
  display: flex;
  align-items: center;
}