.users-page {
    .user-block .row { background-color:#fff;}
    .orange-icon { color: #FF9000; }
    .checkbox-color-checked { color: #FF9000; }
    .block-button { cursor:pointer; z-index:999;}
    .disabled-link { color: #c0c0c0;}
    .filter-row { background-color:#fff; padding:16px 0px 10px 10px;}
    .user-table { width:100%; }
    .user-table thead { font-weight:bold; }
    .media-modal-image-preview h4 { text-overflow: ellipsis;

      /* Required for text-overflow to do anything */
      white-space: nowrap;
      overflow: hidden; }

    /* Important part */
    .modal-dialog{
        overflow-y: initial !important
    }
    .modal-body{
        max-height: 80vh;
        overflow-y: auto;
    }
    .main-header__footer {
      position: absolute;
      bottom: 56px;
      right: calc(180px + 56px);
      left: 56px;
    }
    .main-header-footer__title {
      color: #fff;
      font-weight: 600;
      font-size: 20px;
      line-height: 23px;
      margin: 0;
    }
    .main-header-footer__navigation {
      display: flex;
      margin: 0;
      padding: 0;
      list-style: none;
      font-size: 16px;
    }
    .main-header-footer__navigation li {
      margin-right: 16px;
      &:last-child {
        margin-right: 0;
      }
    }
    .main-header-footer__navigation li a {
      display: block;
      font-weight: 500;
      color: rgba(255, 255, 255, .4);
      text-decoration: none;
      transition: .2s linear;
      &:hover {
        color: rgba(255, 255, 255, .8);
      }
    }
    .main-header__daan {
      color: #fff;
      position: absolute;
      bottom: 56px;
      right: 56px;
      cursor: pointer;
    }
    .main-header-daan__subtitle {
      font-size: 20px;
      line-height: 18px;
      color: rgba(255, 255, 255, .4);
    }
    .main-header-daan__title {
      font-size: 32px;
      line-height: 32px;
      font-weight: 700;
      position: relative;
    }
    .main-header-daan__title .material-icons {
      position: absolute;
      font-size: 24px;
      left: -30px;
      bottom: -5px;
    }

    .main-header__search {
      width: 600px;
      color: #fff;
      position: absolute;
      left: calc(50% - 300px);
      top: calc(50% - (164px / 2));
    }
    .main-header-search__title {
      font-size: 48px;
      line-height: 56px;
      margin: 0;
      padding: 0;
      text-align: center;
      font-weight: 900;
    }
    .main-header-search__caption {
      color: rgba(255, 255, 255, .7);
      font-size: 32px;
      line-height: 32px;
      font-weight: 400;
      text-align: center;
    }
    .main-header-search__section {
      margin-top: 30px;
      background: #fff;
      height: 46px;
      border-radius: 46px;
      padding: 1px;
      border: 1px solid #fff;
    }
    .main-header-search-section__text-field {
      border: none;
      height: 42px;
      float: left;
      width: 270px;
      font-family: 'Roboto', sans-serif;
      border-top-left-radius: 42px;
      border-bottom-left-radius: 42px;
      padding-left: 22px;
      padding-right: 22px;
      line-height: 42px;
      font-size: 14px;
      outline: none;
    }
    .main-header-search-section__select-field {
      float: left;
      border-left: 1px solid #E5E5E5;
      color: rgba(0, 0, 0, .4);
      height: 42px;
      line-height: 42px;
      font-size: 14px;
      padding-left: 8px;
      padding-right: 16px;
      position: relative;
      &:after {
        position: absolute;
        font-family: "Material Icons";
        display: block;
        content: 'arrow_drop_down';
        right: 4px;
        top: 0;
        bottom: 0;
      }
    }
    .main-header-search-section__select-field:nth-child(2) {
      width: 110px;
    }
    .main-header-search-section__select-field:nth-child(3) {
      width: 119px;
    }
    .main-header-search-section__button {
      float: right;
      border: 1px solid #FF9000;
      font-size: 14px;
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      color: #fff;
      background: #FF9000;
      height: 42px;
      border-radius: 42px;
      display: inline-flex;
      align-items: center;
      padding-left: 24px;
      padding-right: 24px;
      &:hover {
        color: #fff;
        background: #e27400;
        border: 1px solid #e27400;
      }
    }
    .upload_form {
      height:165px;
    }
    .user-block {
      padding-top: 380px;
      padding-bottom: 80px;
    }
    .user-block__item {
      background: #eee;
      background-size: cover;
      color: #fff;
      height: 175px;
      position: relative;
      padding: 16px 18px;
      transform: scale(1);
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0);
      transition: transform .2s linear, box-shadow .2s linear;
      background-size: cover;
      a {
        background: transparent;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: -1;
        display: block;
        transition: background .2s linear;
      }
      &:hover {
        transform: scale(1.1);
        z-index: 3;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        a {
          background: rgba(255, 144, 0, .8);
        }
      }
    }
    .user-block-item__title {
      font-size: 28px;
      line-height: 32px;
      font-weight: 900;
      margin: 0;
    }
    .user-block-item__caption {
      font-size: 16px;
      line-height: 16px;
      color: rgba(255, 255, 255, .7);
    }
    .user-block-item__delete {
      position: absolute;
      width: 44px;
      height: 44px;
      right: 18px;
      bottom: 16px;
      border-radius: 44px;
      background: #fff;
      color: #FF9000;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }
    .user-block-item__download {
      position: absolute;
      width: 44px;
      height: 44px;
      right: 90px;
      bottom: 16px;
      border-radius: 44px;
      background: #fff;
      color: #FF9000;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }
    .user-block-item__check {
      position: absolute;
      width: 44px;
      height: 44px;
      left: 10px;
      bottom: 16px;
      border-radius: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }
    .user-block__item .checked {
      background: #fff;
      color: #FF9000;
    }
    .neighbourhood {
      min-height: 400px;
      background: #eeeeee;
      background-position: center;
      background-size: cover;
    }

    .neighbourhood__section {
      color: #fff;
      text-align: center;
    }
    .neighbourhood-section__caption {
      font-size: 20px;
      line-height: 20px;
      color: rgba(255, 255, 255, .7);
    }
    .neighbourhood-section__title {
      font-size: 34px;
      line-height: 34px;
      font-weight: 700;
    }
    .neighbourhood-section__button {
      margin-top: 26px;
    }

    .quality {
      min-height: 460px;
      background: #FAFAFA url("../../../assets/images/duurzaamheid.png") no-repeat;
      background-position: calc(100% - 871px / 2) bottom;
    }
    .quality__caption {
      font-size: 20px;
      line-height: 20px;
      color: rgba(0, 0, 0, .3);
    }
    .quality__title {
      margin-left: auto;
      margin-right: 0;
      font-size: 34px;
      line-height: 34px;
      font-weight: 700;
      max-width: 410px;
    }
    .quality__button {
      margin-top: 26px;
    }

    .working-daan {
      padding-top: 30px;
      padding-bottom: 30px;
      background: #333 url("../../../assets/images/werken-bij-daan.png") center no-repeat;
      background-size: cover;
      min-height: 430px;
    }
    .working-daan__signup {
      margin-left: 30px;
      margin-right: 60px;
      background: rgba(255, 255, 255, .8);
      min-height: 370px;
      padding: 36px 42px;
    }
    .working-daan-signup__title {
      font-size: 34px;
      line-height: 38px;
      text-align: center;
      font-weight: 700;
      margin-bottom: 24px;
    }
    .working-daan-signup__button {
      margin-top: 26px;
    }

    .working-daan__title {
      font-size: 48px;
      line-height: 50px;
      max-width: 445px;
      font-weight: 700;
      color: #fff;
      margin: 0 0 38px;
    }

    @include media-breakpoint-up(xl) {
      .main-header {
        min-height: 100px;
        background: url("../../../assets/images/homepage-header.png") center top no-repeat;
        background-size: cover;
      }
    }
  }
