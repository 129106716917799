
// Fonts
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900");

// Variables
@import "variables";

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Components
@import "daan/base";
@import "daan/components/header";
@import "daan/components/footer";
@import "daan/components/navigation";
@import "daan/components/buttons";
@import "daan/components/form";
@import "daan/components/social";
@import "daan/components/modals";
@import "daan/components/animate";
@import "daan/react/daan";

// Pages
@import "daan/pages/page";
@import "daan/pages/front-page";
@import "daan/pages/media-page";
@import "daan/pages/login-page";
@import "daan/pages/users-page";

.footer-scroll-top {
  display: none;
  width: 65px;
  height: 65px;
  background: #e5e5e5;
  border: #c7c7c7;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 50;
  cursor: pointer;
  .footer-scroll-top__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65px;
    height: 65px;
    color: #C7C7C7;
    .material-icons {
      font-size: 32px;
    }
  }
}