.JobApplicationModal {
  width: 50vw;
  min-width: 320px;
  max-width: 620px;
  position: fixed;
  z-index: 1100;
  right: -50vw;
  height: 100vh;
  overflow: auto;
  background: #fff;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  transition: right .2s ease-in;
}

.JobApplicationModal-enter.Backdrop-enter-active {
  right: 0;
}
.JobApplicationModal-enter-done {
  right: 0;
}

.JobApplicationModal__header {
  height: 150px;
  background: #FAFAFA;
  padding: 30px;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.JobApplicationModal-header__title {
  margin: 0;
  padding: 0;
  font-size: 26px;
  line-height: 32px;
  font-weight: 700;
}
.JobApplicationModal-header__caption {
  margin-top: 10px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  color: #969696;
}
.JobApplicationModal-header__description-details {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  height: 24px;
  color: #969696;
}
.JobApplicationModal-header-description__detail {
  float: left;
  margin-right: 10px;
}

.JobApplicationModal__navigation {
  height: 90px;
  display: flex;
  justify-content: flex-end;
}
.JobApplicationModal-navigation__selection {
  margin-left: 0;
  margin-right: auto;
  color: #D9D9D9;
  font-size: 20px;
  font-weight: 700;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 2rem;
  padding-right: 2rem;
  .material-icons {
    margin-left: 10px;
  }
}
.JobApplicationModal-navigation__item {
  width: 110px;
  height: 90px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: #F4F4F4;
  color: rgba(0, 0, 0, 0.23);
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: background .2s linear, color .2s linear;
  cursor: pointer;
  .material-icons {
    font-size: 36px;
    transition: color .2s linear;
  }
  &.is_active {
    background: #fff;
    color: #000;
    .material-icons {
      color: #FF9000;
    }
  }
}
.JobApplicationModal-navigation__item:hover {
  background: #fff;
  color: #000;
  .material-icons {
    color: #FF9000;
  }
}

.JobApplicationModal__form {
  padding: 30px 15px;
  .btn-link {
    color: rgba(0, 0, 0, 0.28);
    text-decoration: underline;
    font-size: 12px;
  }
  textarea {
    min-height: 170px;
    resize: none;
  }
  .form-control {
    border-radius: 4px;
    border: 1px solid rgba(0,0,0, .1);
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    &::placeholder {
      color: rgba(0, 0, 0, 0.15);;
    }
  }
}