.BackDrop {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  height: 100vh;
  background: rgba(0, 0, 0, .15);
  overflow: hidden;
  opacity: .01;
  transition: .2s opacity;
}
.Backdrop-enter.Backdrop-enter-active {
  opacity: 1;
}
.Backdrop-enter-done {
  opacity: 1;
}