@import "material-icons";

html {
  -webkit-font-smoothing: antialiased;
}

.col-small-padding {
  padding: 4px;
}

.no-margin {
  margin: 0 !important;
}

.main-header {
  min-height: 400px;
  background: #444;
}

.red-link {
  margin:5px 5px 5px 15px;
  color:red !important;
}

a { cursor:pointer; }

a.disabled-link {color: #ccc !important;}
a.orange-icon {color: #FF9000 !important;}
.pull-right { float:right; }