.Auth {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
  }
  .login-page .header { display:none; }
  .Splash {
    height: 100vh;
    background: #ccc;
    flex-grow: 2;
    background: url('../../../assets/images/portal.jpg') center no-repeat;
    background-size: cover;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Splash:before {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    content: '';
    background: rgba(0, 0, 0, 0.4);
  }
  .SplashContent {
    color: #ffffff;
    display: flex;
    margin: 16px;
    align-items: center;
    position: relative;
  }
  .SplashContent h1 {
    font-size: 86px;
    line-height: 1;
    margin: 0;
  }
  .SplashContent p {
    font-size: 32px;
    font-weight: 300;
    margin: 0;
  }
  .SplashContent .Logo {
    background: url('../../../assets/images/logo.png') center no-repeat;
    width: 200px;
    height: 200px;
  }

  .Login {
    background: #fff;
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 350px;

    justify-content: center;
    align-items: center;

    background: url('../../../assets/images/background-login.png') center;
    background-size: cover;

    text-align: center;
    color: #434343;
  }
  .Login h2 {
    font-size: 34px;
    margin: 0;
  }
  .Login p {
    font-size: 22px;
    margin: 0;
    color: rgba(41, 41, 41, 0.46);
    margin-bottom: 36px;
  }
  .Login form {
    width: 75%;
    min-width: 175px;
    max-width: 400px;
  }
  .Login input[type='text'],
  .Login input[type='email'],
  .Login input[type='password'] {
    color: #434343;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    display: block;
    width: 100%;
    height: 46px;
    margin-bottom: 12px;
    font-size: 14px;
    box-sizing: border-box;
    padding: 12px;
    outline: none;
  }
  .Login input::placeholder {
    color: #cdcdcd;
    font-family: 'Roboto', sans-serif;
  }
  .Login button {
    display: block;
    width: 100%;
    border: none;
    background: #ff8e01;
    color: #ffffff;
    height: 46px;
    border-radius: 46px;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.2s ease-out;
  }

  .Login button:hover {
    background: #e27500;
  }
  .Login .ErrorMessage {
    margin: 12px;
    font-weight: bold;
    color: #ff0000;
  }

  .Login .AdditionalLink {
    margin-top: 12px;
    margin-bottom: 12px;
    text-decoration: underline;
  }

  .Login .AdditionalLink:hover {
    cursor: pointer;
  }

  .Login .EmailSentMessage {
    font-size: 1.2em;
    max-width: 300px;
  }

  .Helpers {
    display: flex;
    margin-top: 16px;
    margin-bottom: 24px;
    margin-left: 2px;
    margin-right: 2px;
  }
  .Helpers a {
    margin-left: auto;
    margin-right: 0;
    color: #434343;
  }

  @media (max-width: 991.98px) {
    .SplashContent {
      flex-direction: column;
      align-items: flex-start;
    }
    .SplashContent h1 {
      font-size: 50px;
    }
    .SplashContent .Logo {
      width: 120px;
      height: 120px;
      background-size: 134px 120px;
    }
  }

  @media (max-width: 767.98px) {
    .Auth {
      flex-direction: column;
    }
    .Splash {
      height: 37%;
      min-width: 300px;
    }
    .Login {
      height: 63%;
      width: 100%;
      min-width: 300px;
    }
  }

  @media (max-width: 575.98px) {
    .SplashContent h1 {
      font-size: 32px;
    }
    .SplashContent p {
      font-size: 24px;
    }
    .SplashContent .Logo {
      width: 80px;
      height: 80px;
      background-size: 90px 80px;
    }
  }
